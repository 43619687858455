import React from 'react'
import { containPresenter } from '../../utils/HoC'
import moment from 'moment'
import 'moment/locale/ja'

// Container
export const TimeContainer = ({
  presenter,
  children: value,
  dateTime,
  format = 'YYYY.MM.DD',
  ...props
}) => {
  value = parseInt(Date.parse(value.replace(/\s+/g, 'T')), 10)

  var children
  if (!isValid(value)) {
    children = '有効な時間表現ではありません'
  } else {
    children = formatDatetime(value, format)
  }

  if (!dateTime) {
    dateTime = formatDatetime(value)
  }

  return presenter({ children, dateTime, ...props })
}

// Presenter
export const TimePresenter = (props) => <time {...props} />

const Time = containPresenter(TimeContainer, TimePresenter)
export default Time

// Script
moment.locale()

function isValid(unixtime) {
  return moment(unixtime, 'x', true).isValid()
}

function formatDatetime(datetime, format = 'YYYY-MM-DDTHH:mm') {
  return moment(datetime).format(format)
}
