import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import homeListData from '../../content/home.json'

// components
import Notice from '../components/organisms/TheNotice'
import Faq from '../components/organisms/TheFaq'
import Layout from '../components/templates/Default'

// template
const Home = ({ data }) => {
  const allFaq = data.faq
  const homeNotice = homeListData[0].notices.reduce((a, v) => {
    if (
      !a.some(
        (e) =>
          e.title === v.title &&
          e.date === v.date &&
          e.link === v.link &&
          e.blank === v.blank
      )
    ) {
      a.push(v)
    }
    return a
  }, [])
  let homeFaq = []
  homeListData[1].faqs.forEach(function (slug) {
    allFaq.edges.forEach(function (post) {
      if (slug === post.node.fields.slug && homeFaq.includes(post) === false) {
        homeFaq.push(post)
      }
    })
  })
  return (
    <Layout pageType="home">
      <Helmet>
        <html lang="ja" />
        <meta charSet="utf-8" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Cache-Control" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
        <title>アフィリエイト管理画面ヘルプ</title>
      </Helmet>
      <Notice listData={homeNotice} />
      <Faq listData={homeFaq} />
    </Layout>
  )
}

export default Home

// script
export const pageQuery = graphql`
  query {
    faq: allMarkdownRemark(
      filter: { frontmatter: { kind: { eq: "faq" }, posttype: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            kind
            posttype
            cat
            order
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
