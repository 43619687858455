import React from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Heading from '../atoms/BaseHeading'
import Txt from '../atoms/BaseTxt'
import ListItem from '../molecules/ListItem'

// template
const TheFaq = (props) => (
  <section>
    <Heading level={2} className="title-page">
      よくあるご質問
    </Heading>
    <PageList>
      {props.listData.map(({ node }) => (
        <ListItem key={node.id} link={node.fields.slug}>
          <Txt type={'span'} className="anchor-text">
            {node.frontmatter.title}
          </Txt>
        </ListItem>
      ))}
    </PageList>
  </section>
)

export default TheFaq

// style
const PageList = styled.ul`
  border: 1px solid ${vc_color.border};
  border-radius: ${vc_base.pixel`0.5`};
  margin-top: ${vc_base.pixel};
`
