import React from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'
import { media } from '../../assets/js/Helper'

// components
import Anchor from '../atoms/BaseAnchor'

// template
const ListItem = (props) => (
  <PageListItem>
    {(() => {
      if (props.blank) {
        return (
          <PageListLink
            type={'a'}
            href={props.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.children}
          </PageListLink>
        )
      } else {
        return <PageListLink to={props.link}>{props.children}</PageListLink>
      }
    })()}
  </PageListItem>
)

export default ListItem

// style
const PageListItem = styled.li`
  border-top: 1px solid ${vc_color.border};
  &:first-child {
    border-top: none;
  }
`
const PageListLink = styled(Anchor)`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${vc_base.pixel`1.5`};
  padding-left: ${vc_base.pixel`1.5`};
  padding-right: ${vc_base.pixel`1.5`};
  padding-top: ${vc_base.pixel`1.5`};
  ${media.Large`
		flex-wrap: nowrap;
		padding-top: ${vc_base.pixel`2.5`};
		padding-bottom: ${vc_base.pixel`2.5`};
		padding-left: ${vc_base.pixel`2.5`};
		padding-right: ${vc_base.pixel`2.5`};
	`}
`
