import React from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Heading from '../atoms/BaseHeading'
import Txt from '../atoms/BaseTxt'
import Caption from '../atoms/BaseCaption'
import Time from '../atoms/BaseTime'
import ListItem from '../molecules/ListItem'

// template
const TheNotice = (props) => (
  <section>
    <Heading level={2} className="title-page">
      お知らせ
    </Heading>
    <PageList>
      {props.listData.map((node, index) => {
        return (
          <ListItem
            key={index}
            link={node.link}
            href={node.link}
            blank={node.blank}
          >
            <NoticeTimeBox type={'span'}>
              <NoticeTime>{node.date}</NoticeTime>
            </NoticeTimeBox>
            <Txt type={'span'} className="anchor-text">
              {node.title}
            </Txt>
          </ListItem>
        )
      })}
    </PageList>
  </section>
)

export default TheNotice

// style
const PageList = styled.ul`
  border: 1px solid ${vc_color.border};
  border-radius: ${vc_base.pixel`0.5`};
  margin-top: ${vc_base.pixel};
`
const NoticeTimeBox = styled(Caption)`
  font-weight: bold;
  margin-right: 1em;
  max-width: 7em;
`
const NoticeTime = styled(Time)`
  color: ${vc_color.dark};
  line-height: 2;
`
